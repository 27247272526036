import useAxios from "axios-hooks";
import Loading from "components/loading";
import { useState } from "react";
import {
  IoAddCircleOutline,
  IoChevronDownCircleOutline,
} from "react-icons/io5";
import ActivityViewTableRow from "views/admin/ministry/components/ActivityViewTableRow";
import AssAcctivityRowForm from "views/admin/ministry/components/AssAcctivityRowForm";

function ActivitiesList({
  activities,
  mileId,
  handleActitvitySubmit,
  milestone,
  loading,
  getMile,
  isUserAssigned,
  setShowActivity,
  showActivity,
  setShowActivityForm,
  showActivityForm,
}) {
  const [editActivity, setEditActivity] = useState(false);
  const [activity, setActivity] = useState({});

  const [{ loading: postLoading }, postActivity] = useAxios(
    {
      url: "/ministry_tasks/assignment-activities/",
      method: "POST",
    },
    { manual: true }
  );

  const handleRemoveActivity = async (id) => {
    if (!id) {
    }
    postActivity({
      url: `/ministry_tasks/assignment-activities/${id}/`,
      method: "DELETE",
    }).then(() => {
      getMile();
    });
  };

  const allLoading = loading || postLoading;

  return (
    <div className="mt-6 rounded-lg bg-[#E9EDF5] py-1 px-2 dark:bg-navy-900">
      <div className="flex justify-between">
        <h1 className="ml-3 pt-3 font-semibold text-navy-500">
          {milestone?.assignment_milestone_name || "Milestone Name"}
        </h1>

        {/* {isUserAssigned && (
          <BsTrashFill
            className="mt-3 mr-3 h-4 w-4 cursor-pointer text-red-500"
            onClick={() => {
              window.document
                .getElementById(`DeleteModal${mileId + activity?.id}`)
                .classList.replace("hidden", "flex");
            }}
          />
        )} */}
      </div>
      {/*  */}
      {allLoading ? (
        <Loading />
      ) : (
        <div className="m-3">
          <div className="flex  items-center justify-between rounded-lg bg-white px-4 py-2  dark:bg-gray-200 ">
            <span className="text-sm text-gray-700 dark:text-gray-900 ">
              Activities
            </span>
            <div className={`flex items-center gap-2`}>
              {isUserAssigned && (
                <IoAddCircleOutline
                  className="h-5 w-5 cursor-pointer text-brand-600"
                  onClick={() => {
                    setShowActivity(false);
                    setShowActivityForm(!showActivityForm);
                  }}
                />
              )}
              {/* {activity?.id && ( */}
              <IoChevronDownCircleOutline
                color="#687182"
                className={`h-5 w-5 transform cursor-pointer ${
                  showActivity ? "rotate-180" : "rotate-0"
                }`}
                onClick={() => {
                  setShowActivity(!showActivity);
                  setShowActivityForm(false);
                }}
              />
              {/* )} */}
            </div>
          </div>
          {showActivity && (
            <ActivityViewTableRow
              activity={activity}
              activities={activities}
              refetch={getMile}
              isUserAssigned={isUserAssigned}
              handleRemoveActivity={handleRemoveActivity}
              showEditForm={(act) => {
                setActivity(act);
                setEditActivity(true);
                setShowActivity(false);
                setShowActivityForm(!showActivityForm);
              }}
            />
          )}

          <AssAcctivityRowForm
            edit={editActivity}
            setEdit={setEditActivity}
            data={activity}
            setShowMilestoneForm={setShowActivityForm}
            showMilestoneForm={showActivityForm}
            handleSave={async (formData, edit) => {
              handleActitvitySubmit(formData, edit);
              setShowActivity(!showActivity);
              setShowActivityForm(false);
            }}
          />
        </div>
      )}
    </div>
  );
}
export default ActivitiesList;
