import DropdownControlled from "components/dropdown/DropdownControlled";
import { statusOptions } from "components/dropdown/StatusDropdown";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";

function StatusDropdownAssAct({ button, pos, onSubmit, status }) {
  const [selectedStatus, setSelectedStatus] = useState("not_started");
  const [openWrapper, setOpenWrapper] = useState(false);
  const [memo, setMemo] = useState("");

  useEffect(() => {
    if (status) {
      setSelectedStatus(status);
    }
  }, [status]);

  return (
    <DropdownControlled
      openWrapper={openWrapper}
      setOpenWrapper={setOpenWrapper}
      id="status-dropdown"
      classNames={`pt-2 top-12 ${
        pos ? pos : "-left-18"
      } w-max shadow-xl rounded-lg`}
      button={button ? button : <FaChevronDown className="cursor-pointer" />}
      children={
        <div className="min-w-[250px] rounded-xl bg-white p-4 text-sm">
          <h1 className="mb-3 text-start font-semibold">Change status</h1>
          <ul className="flex flex-col gap-1 border-b">
            {statusOptions.map((status, index) => (
              <li
                key={index}
                className={`cursor-pointer ${
                  selectedStatus === status.title
                    ? "flex w-full items-center justify-between rounded-lg bg-[#5E5ADB] px-2 text-white"
                    : "hover:bg-gray-50"
                }`}
                onClick={() => setSelectedStatus(status.title)}
              >
                <div className="my-2 flex  items-center gap-2 ">
                  <div
                    className={`ml-1 h-3 w-3 rounded-sm `}
                    style={{ background: status.color }}
                  />
                  <p className="mr-2">{status.title.toUpperCase()}</p>
                </div>
              </li>
            ))}
          </ul>
          {selectedStatus !== "not_started" && (
            <div className="mt-4 flex w-full flex-grow flex-col text-start">
              <div>
                <h1>Memo :</h1>
                <p className="text-xs text-gray-500">Type Status Memo</p>
              </div>
              <input
                id="statusMemo"
                type="text"
                placeholder="Status Memo"
                className="mt-1 rounded-lg bg-gray-50 py-4 px-2"
                onChange={(e) => setMemo(e.target.value)}
                value={memo}
              />
            </div>
          )}
          <div className="mt-2 flex w-full items-center justify-end gap-2">
            <div
              className="cursor-pointer rounded-lg p-2 px-4 hover:bg-gray-50 dark:text-gray-800"
              onClick={() => {
                setMemo("");
                setOpenWrapper(false);
              }}
            >
              Cancel
            </div>
            <div
              className="cursor-pointer rounded-lg p-2 px-4 text-[#5E5ADB] hover:bg-navy-50"
              onClick={() => {
                setOpenWrapper(false);
                onSubmit({ status: selectedStatus, memo });
              }}
            >
              Apply
            </div>
          </div>
        </div>
      }
    />
  );
}
export default StatusDropdownAssAct;
