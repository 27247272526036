import useAxios from "axios-hooks";
import Dropdown from "components/dropdown";
import StatusDropdownAssAct from "components/dropdown/StatusDropdownAssAct";
import AddIconFilled from "components/icons/AddIconFilled";
import ChevronDownOutline from "components/icons/ChevronDownOutline";
import ChevronRightOutline from "components/icons/ChevronRightOutline";
import AssActivityViewModal from "components/popover/AssActivityViewModal";
import StatusShow from "components/StatusShow";
import { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import DeleteModal from "views/admin/expert/components/DeleteModal";
import AssSubActivityModal from "views/admin/ministry/components/AssSubActivityModal";
import AssSubActivityRow from "views/admin/ministry/components/AssSubActivityRow";

function ActivityViewTableRow({
  activities,
  refetch,
  isUserAssigned,
  handleRemoveActivity,
  showEditForm,
}) {
  const parentActs = activities.filter(
    (act) => act.assignment_parent_activity === null
  );

  const getSubActs = (activity) => {
    const subActs = activities.filter(
      (act) => act.assignment_parent_activity === activity.id
    );
    return subActs ? subActs : [];
  };

  const [, updateStatus] = useAxios(
    {
      method: "PUT",
    },
    { manual: true }
  );

  const handleStatusUpdate = (value, activity) => {
    updateStatus({
      data: {
        ...activity,
        status: value.status,
        ongoing_status_memo: value.memo,
      },
      url: `/ministry_tasks/assignment-activities/${activity.id}/`,
      method: "PUT",
    }).then(() => {
      refetch();
    });
  };

  return (
    <table className="mt-3 w-full table-auto rounded bg-white dark:bg-navy-900">
      <thead>
        <tr className="bg-[#F7F9FCCC] text-[#687182]">
          <th></th>

          <th className="text-start font-normal">
            <p className="ml-4 max-w-[250px] truncate">Name</p>
          </th>
          <th className="font-normal">Description</th>
          {isUserAssigned && <th className="font-normal">Sub Activities</th>}
          <th>
            <div className="flex items-center justify-center gap-2 font-normal uppercase">
              <p>status</p>
            </div>
          </th>
          <th className="ml-4 flex items-center justify-center gap-2 font-normal uppercase">
            <p>Timeline</p>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody className="font-light">
        {parentActs?.map((activity) => (
          <ActivityRow
            key={activity.id}
            activity={activity}
            isUserAssigned={isUserAssigned}
            handleStatusUpdate={handleStatusUpdate}
            handleRemoveActivity={handleRemoveActivity}
            refetch={refetch}
            showEditForm={showEditForm}
            subActivities={getSubActs(activity)}
          />
        ))}
      </tbody>
    </table>
  );
}

const ActivityRow = ({
  activity,
  isUserAssigned,
  handleStatusUpdate,
  handleRemoveActivity,
  refetch,
  showEditForm,
  subActivities,
}) => {
  const [showChileDel, setShowChileDel] = useState(false);
  const [view, setView] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  return (
    <>
      <tr
        key={activity.id}
        className={`table-row overflow-x-auto ${
          showChileDel ? "bg-[#EDEDFC] dark:bg-navy-900 " : ""
        }`}
      >
        <td className="w-[40px]">
          <div className="ml-4 w-fit ">
            {showChileDel ? (
              <ChevronDownOutline
                onClick={() => setShowChileDel(!showChileDel)}
                className="cursor-pointer fill-[#171C26] dark:fill-white"
              />
            ) : (
              <ChevronRightOutline
                onClick={() => setShowChileDel(!showChileDel)}
                className="cursor-pointer fill-[#171C26] dark:fill-white"
              />
            )}
          </div>
        </td>
        <td className="w-[30%]">
          <div
            className={`} truncate text-start
                dark:text-white`}
          >
            <p className="ml-4 dark:text-white">
              {activity?.assignment_activity_name}
            </p>
          </div>
        </td>
        <td>
          <div className="flex justify-center">
            <p className="w-[110px] truncate text-center dark:text-white">
              {activity?.assignment_activity_description || "N/A"}
            </p>
          </div>
        </td>
        {isUserAssigned && (
          <td>
            {viewForm && (
              <AssSubActivityModal
                activity={activity}
                key={activity.id}
                setOpen={setViewForm}
                refetch={refetch}
              />
            )}
            <div
              className="flex cursor-pointer justify-center"
              onClick={() => {
                setViewForm(true);
              }}
            >
              <AddIconFilled className="h-8 w-8 " />
            </div>
          </td>
        )}
        <td className="flex justify-center">
          {activity?.status !== "completed" && isUserAssigned ? (
            <StatusDropdownAssAct
              pos={"-left-28"}
              button={
                <StatusShow status={activity?.status} key={activity?.id}>
                  <FaChevronDown size={10} className="cursor-pointer" />
                </StatusShow>
              }
              onSubmit={(value) => {
                handleStatusUpdate(value, activity);
              }}
            />
          ) : (
            <StatusShow status={activity?.status} />
          )}
        </td>
        <td>
          <div className="flex w-full items-center justify-center gap-1">
            <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-navy-800">
              {activity?.start_date?.substring(0, 10)}
            </p>
            <BsChevronRight size={10} />
            <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-navy-800">
              {activity?.end_date?.substring(0, 10)}
            </p>
          </div>
        </td>
        <td>
          {view && (
            <AssActivityViewModal
              id={"activity" + activity.id}
              data={activity}
              setOpen={setView}
            />
          )}
          <DeleteModal
            key={activity?.id}
            id={`DeleteActModal${activity?.id}`}
            handleCancel={() => {
              window.document
                .getElementById(`DeleteModal${activity?.id}`)
                .classList.replace("flex", "hidden");
            }}
            handleConfirm={() => {
              handleRemoveActivity(activity?.id);
              window.document
                .getElementById(`DeleteActModal${activity?.id}`)
                .classList.replace("flex", "hidden");
            }}
          />
          <Dropdown
            button={<HiDotsVertical className="cursor-pointer" />}
            id={`dropdown${activity.id}`}
            classNames={"top-5 min-w-max -left-24"}
            children={
              <div className="flex flex-col items-start gap-2 rounded-md bg-white px-1 py-5 dark:bg-navy-900">
                <p
                  className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                  onClick={() => {
                    setView(true);
                  }}
                >
                  View
                </p>
                {isUserAssigned && (
                  <p
                    className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                    onClick={() => showEditForm(activity)}
                  >
                    Edit
                  </p>
                )}
                {isUserAssigned && (
                  <p
                    className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                    onClick={() => {
                      window.document
                        .getElementById(`DeleteActModal${activity?.id}`)
                        .classList.replace("hidden", "flex");
                    }}
                  >
                    Delete
                  </p>
                )}
              </div>
            }
          />
        </td>
      </tr>
      {showChileDel &&
        subActivities?.map((subActivity) => (
          <AssSubActivityRow
            key={subActivity.id}
            subActivity={subActivity}
            handleRemoveActivity={handleRemoveActivity}
            isUserAssigned={isUserAssigned}
            refetch={refetch}
          />
        ))}
    </>
  );
};

export default ActivityViewTableRow;
