import { useEffect, useState } from "react";
import Loading from "components/loading";
import useAxios from "axios-hooks";
import SearchIcon from "components/icons/SearchIcon";
import Error from "components/error";
import ActivitiesList from "views/admin/ministry/components/ActivitiesList";

function AssignmentActivityForm({ data, refetch }) {
  const [search, setSearch] = useState("");
  const [activities, setActivities] = useState([]);
  const [showActivity, setShowActivity] = useState(false);
  const [showActivityForm, setShowActivityForm] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  const [{ data: milestone, loading: getLoading, error: getError }, getMile] =
    useAxios(
      {
        url: `/ministry_tasks/assignment-milestones/${data}/`,
        method: "GET",
      },
      { useCache: false }
    );

  const [{ loading: postLoading, error: postError }, postActivity] = useAxios(
    {
      url: "/ministry_tasks/assignment-activities/",
      method: "POST",
    },
    { manual: true }
  );

  const handleActitvitySubmit = async (formData, edit) => {
    const newActivity = {
      assignment_milestone: data,
      assignment_activity_name: formData.activityName,
      assignment_activity_description: formData.activityDescription,
      start_date: formData.startDate,
      end_date: formData.endDate,
      status: formData.status,
    };
    await postActivity({
      data: newActivity,
      method: edit ? "PUT" : "POST",
      url: edit
        ? `/ministry_tasks/assignment-activities/${formData.id}/`
        : "/ministry_tasks/assignment-activities/",
    }).then(() => {
      getMile();
      return true;
    });
  };

  useEffect(() => {
    if (milestone) {
      const allActivities = milestone?.assignment_activity || [];
      setActivities([...allActivities]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestone]);

  const loading = getLoading || postLoading;
  const error = getError || postError;
  const isUserAssigned = milestone?.assigned_to.includes(user?.user);

  return (
    <div className="flex min-h-[65vh] w-full flex-grow flex-col justify-between rounded-lg bg-[#F2F9FFCC] dark:bg-navy-700">
      <div>
        <div className="mt-2 flex flex-col items-end justify-end gap-2 md:flex-row md:items-center md:gap-0">
          <div className="flex items-center rounded-l-md bg-white dark:bg-navy-900">
            <SearchIcon />
            <input
              type="text"
              placeholder={true ? "Search Activities" : "Search Milestones"}
              className=" w-full py-1.5 px-4 focus:outline-none dark:bg-navy-900 dark:text-white dark:placeholder-gray-400"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {isUserAssigned && (
            <button
              className="rounded bg-[#485FE5] px-4 py-1.5 text-white md:rounded-none md:rounded-r-md"
              onClick={() => {
                setShowActivity(false);
                setShowActivityForm(!showActivityForm);
              }}
            >
              {true ? "Add Activity" : "Add Executing Body"}
            </button>
          )}
        </div>

        {loading && <Loading />}
        {error && <Error error={error} />}

        {!loading && (
          <ActivitiesList
            activities={activities}
            getMile={getMile}
            isUserAssigned={isUserAssigned}
            handleActitvitySubmit={handleActitvitySubmit}
            loading={loading}
            mileId={data}
            setShowActivity={setShowActivity}
            showActivity={showActivity}
            showActivityForm={showActivityForm}
            setShowActivityForm={setShowActivityForm}
            milestone={milestone}
          />
        )}
      </div>
    </div>
  );
}

export default AssignmentActivityForm;
