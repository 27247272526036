import StatusShow from "components/StatusShow";
import { FiX } from "react-icons/fi";

function AssActivityViewModal({ id, data, setOpen }) {
  return (
    <div
      id={id || "ActivityViewModal"}
      tabIndex="-1"
      className="fixed inset-0 z-40 flex w-full items-center justify-center overflow-y-scroll bg-gray-900/50 dark:bg-gray-900/80"
    >
      <div className="relative rounded-lg bg-white pb-6 dark:bg-navy-800 md:min-w-[400px]">
        <button
          tabIndex="-1"
          className="absolute top-4 right-5"
          onClick={() => {
            setOpen(false);
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>

        <div className="mt-12 flex flex-col items-center px-6 text-center lg:px-12">
          <h1
            className={`max-w-[600px] text-start font-semibold text-gray-900 ${
              data?.assignment_activity_name?.length > 20
                ? "text-2xl"
                : "text-3xl"
            }`}
          >
            {data?.assignment_activity_name || "Activity Name"}
          </h1>
          <div className="mt-8 mb-2 flex max-w-[500px] flex-wrap items-center gap-2 self-start">
            <p className="text-start text-lg font-bold">Description:</p>
            <p className="text-start text-gray-700">
              {data?.assignment_activity_description || "N/A"}
            </p>
          </div>

          <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
            <p className="text-start text-lg font-bold">Created Date:</p>
            <p className="text-start text-gray-700">
              {data?.created_date?.substring(0, 10)}
            </p>
          </div>
          <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
            <p className="text-start text-lg font-bold">Created By:</p>
            <p className="text-start text-gray-700">
              {data?.created_by?.profile?.first_name || "N/A"}
              {data?.created_by?.profile?.last_name}
            </p>
          </div>
          <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
            <p className="text-start text-lg font-bold">Start Date:</p>
            <p className="text-start text-gray-700">{data?.start_date}</p>
          </div>
          <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
            <p className="text-start text-lg font-bold">End Date:</p>
            <p className="text-start text-gray-700">{data?.end_date}</p>
          </div>
          {data?.status !== "not_started" && (
            <div className="mt-2 flex max-w-[500px] flex-wrap items-center gap-2 self-start">
              <p className="text-start text-lg font-bold">Memo:</p>
              <p className="text-start text-gray-700">
                {data?.ongoing_status_memo || "N/A"}
              </p>
            </div>
          )}
          <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
            <p className="text-start text-lg font-bold">Status:</p>
            <StatusShow status={data?.status} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default AssActivityViewModal;
